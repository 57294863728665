
<template>
<header>
    <nav class="z-50 fixed top-0 w-full backdrop-blur-md border-b border-gray-300 border-opacity-20 bg-black bg-opacity-20">
        <div class="container">
            <div class="flex flex-wrap items-center justify-between py-2 gap-6 md:py-4 md:gap-0 relative">
                <input aria-hidden="true" type="checkbox" name="toggle_nav" id="toggle_nav" class="hidden peer">
                <div class="relative z-20 w-full flex justify-between max-md:px-4 lg:w-max">
                    <a href="#home" aria-label="logo" class="flex gap-4 space-x-2 items-center">
                        <img src="@/assets/brand/no-bg-logo.png" alt="kreyativ Logo">
                        <span class="text-2xl font-bold text-white">Kreyativ</span>
                    </a>
                    
                    <div class="relative flex items-center lg:hidden max-h-10">
                        <label role="button" for="toggle_nav" aria-label="humburger" id="hamburger" class="relative  p-6 -mr-6">
                            <div aria-hidden="true" id="line" class="m-auto h-0.5 w-5 rounded bg-gray-300 transition duration-300"></div>
                            <div aria-hidden="true" id="line2" class="m-auto mt-2 h-0.5 w-5 rounded bg-gray-300 transition duration-300"></div>
                        </label>
                    </div>
                </div>
                <div aria-hidden="true" class="fixed z-10 inset-0 h-screen w-screen backdrop-blur-2xl origin-bottom scale-y-0 transition duration-500 peer-checked:origin-top peer-checked:scale-y-100 lg:hidden bg-gray-900/70"></div>
                <div class="flex-col z-20 flex-wrap gap-6 p-8 rounded-3xl border justify-end w-full invisible opacity-0 translate-y-1  absolute top-full left-0 transition-all duration-300 scale-95 origin-top 
                            lg:relative lg:scale-100 lg:peer-checked:translate-y-0 lg:translate-y-0 lg:flex lg:flex-row lg:items-center lg:gap-0 lg:p-0 lg:bg-transparent lg:w-7/12 lg:visible lg:opacity-100 lg:border-none
                            peer-checked:scale-100 peer-checked:opacity-100 peer-checked:visible lg:shadow-none 
                            shadow-none bg-gray-800 border-gray-700">
                   
                    <div class="text-gray-300 lg:pr-4 lg:w-auto w-full lg:pt-0">
                        <ul class="tracking-wide font-medium lg:text-sm flex-col flex lg:flex-row gap-6 lg:gap-0">
                            <li>
                                <a href="/" class="block md:px-4 transition hover:text-primary">
                                    <span>Home</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://blog.kreyativ.co/" class="block md:px-4 transition hover:text-primary">
                                    <span>Blog</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://community.kreyativ.co" class="block md:px-4 transition hover:text-primary">
                                    <span>Community</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://blog.kreyativ.co/contact" class="block md:px-4 transition hover:text-primary">
                                    <span>Contact</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="mt-12 lg:mt-0">
                        <a
                            href="https://community.kreyativ.co/"
                            class="hover:scale-105 transition-all relative flex h-9 w-full items-center justify-center px-4 bg-blue-500 rounded-full sm:w-max"
                            >
                            <span class="relative text-sm font-semibold text-white"
                                >Join Community</span
                            >
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</header>
</template>

<style scoped>
    ul li a:hover {
      color: #f1f1f1;
      transition: all 200ms;
    }
    #toggle_nav:checked ~ div #hamburger #line
    {
        @apply rotate-45 translate-y-1.5 
    }

    #toggle_nav:checked ~ div #hamburger #line2
    {
        @apply -rotate-45 -translate-y-1
    }
</style>